import React from 'react'
import ReactPlayer from "react-player"

function VideoPlayer({ videoId }) {
    return (
        <div>
            <ReactPlayer url={`https://www.youtube.com/watch?v=${videoId}`} controls />
        </div>
    )
}

export default VideoPlayer
